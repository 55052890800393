<template>
    <div>
        <div class="row my-3">
            <h1 class="col-sm-12">Quejas</h1>
        </div>

        <DataGrid :configuracion="datagrid_config" :data="quejas" @ready="buscar_btns">
            <button class="btn btn-secondary mr-2" @click="obtener_quejas"><i class="fa-solid fa-rotate"></i>
                Actualizar</button>
            <button class="btn btn-primary" @click="show_modal = true"><i class="fa-solid fa-plus"></i> Crear</button>
        </DataGrid>

        <Form v-if="show_modal" :queja="queja" @close="cerrar_modal" @update="cerrar_modal(true)" />

    </div>
</template>

<script>
import DataGrid from '@/components/DataGridV3.vue';
import apiCondusef from '@/apps/condusef/api/quejas';
import Form from './Quejas/Form.vue';

export default {
    components: { DataGrid, Form },
    data() {
        return {
            datagrid_config: {
                cols: {
                    numero_folio: 'Folio',
                    tipo_penalizacion: 'Penalización',
                    tipo_persona: 'Tipo persona',
                    estatus: 'Estatus',
                    created_at: 'Fecha',
                    otros: 'Otros'
                },
                mutators: {
                    numero_folio(val, row, vue) {
                        return val ? val.substr(0, 6) + '**********' : val;
                    },
                    estatus(val, row, vue) {
                        return "<span class='" + (val == 1 ? 'badge badge-warning' : 'badge badge-success') + "'>" + (val == 1 ? 'Pendiente' : 'Concluido') + "</span>";
                    },
                    tipo_penalizacion(val, row, vue) {
                        switch (val) {
                            case 1:
                                return 'Contractuales - Cancelación del contrato'
                                break;
                            case 2:
                                return 'Contractuales - Reasignación de cartera'
                                break;
                            case 3:
                                return 'Económicas - Multa'
                                break;
                        }
                    },
                    tipo_persona(val, row, vue) {
                        return val == 1 ? 'Persona Física' : 'Persona Moral';
                    },
                    created_at(val, row, vue) {
                        return vue.$moment(val).format('YYYY-MM-DD HH:SS:DD');
                    },
                    otros(val, row, vue) {
                        return '<button class="btn btn-secondary btn-accion" data-queja="' + vue.$helper.toJson(row) + '"><i class="fa-regular fa-edit"></i></button>'
                    }
                },
                selector: false,
            },
            quejas: [],
            queja: null,
            show_modal: false,
        }
    },
    mounted() {
        this.obtener_quejas();
    },
    methods: {
        async obtener_quejas() {
            try {
                this.quejas = (await apiCondusef.listar_quejas()).data;
            } catch (error) {
                this.$log.error('error', error);
                this.$helper.showAxiosError(error, 'Error');
            }
        },
        buscar_btns() {
            document.querySelectorAll('button.btn-accion').forEach(btn => {
                btn.removeEventListener('click', this.abrir_modal);
                btn.addEventListener('click', this.abrir_modal);
            })
        },
        abrir_modal(event) {
            let btn = event.target;

            if (btn.tagName == 'I')
                btn = btn.parentElement;

            this.queja = this.$helper.fromJson(btn.dataset.queja);

            this.show_modal = true;
        },
        cerrar_modal(recargar) {
            if (typeof recargar == ' undefined')
                recargar = false

            this.show_modal = false;
            this.queja = null;

            if (recargar)
                this.obtener_quejas();

        }

    },

}
</script>